<template>
    <div class="header">
        <div>
            <div class="header-logo-div">
                <router-link to="/content">
                    <img v-if="h5logo" class="header-logo-image" :src="h5logo"/>
                </router-link>
            </div>
          <div  style="color: #FFFFFF;float: right;margin-top: 22px;margin-right: 200px;" v-if="whyc_token">
            欢迎您，{{whyc_name}}
            <span class="action" @click="handleLogout">
              <a-icon type="logout" />
              退出登录
            </span>
          </div>
            <div class="header-search-box">
                <input class="header-search-value-input" autocomplete="off" v-model="searchKey" type="text" value=""
                       maxlength="200"
                       placeholder="请输入文件名称搜索">
                <div class="header-search-image-div"><img style="cursor:pointer;"src="../style/img/search-logo/search-logo.png" alt="" @click="search"></div>

            </div>

        </div>
    </div>
</template>

<script>
import {getSystemsetting} from "@/api/content.js";


export default {
    data() {
        return {
            h5logo: "",
            searchKey: undefined,
            whyc_token: localStorage.getItem('whyc_token'),
            whyc_name: localStorage.getItem('whyc_name')
        }
    },
    watch: {
        '$route.query': {
            handler({searchKey}) {
                console.log(searchKey)
                this.searchKey = searchKey || ''
            },
        }
    },
    created() {
        getSystemsetting().then(e => {
            console.log(e.data.result)
            this.h5logo = e.data.result.systemSetting.h5logo
        })
    },
    methods: {
        handleLogout(){
          localStorage.removeItem('whyc_token'),
          localStorage.removeItem('whyc_name')
          window.location.reload()
        },
        search() {
            console.log(this.searchKey);
            this.$router.push({name: 'search', query: {searchKey: this.searchKey}})
        }
    }
}
</script>

<style scoped>
.action{
  margin-left: 10px;
  cursor:pointer;
}
</style>
