import VueRouter from "vue-router";
import Vue from "vue";
import Layout from '../Layout/layout.vue'
import content from '../components/content.vue'
import graphicSource from '../components/graphicSource.vue'
import themeLibrary from '../components/themeLibrary.vue'
import sourceList from '../components/sourceList.vue'
import imageSource from '../components/imageSource.vue'
import modelSource from '../components/modelSource.vue'
import videoSource from '../components/videoSource.vue'
import search from '../components/search.vue'
import UserLayout from '../components/layouts/UserLayout.vue'
import {getResFileList, resFileViewLog} from '@/api/content'


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: 'content',
        children: [
            {
                path: '/content',
                name: 'content',
                component: content,
            },
            {
                path: '/graphicSource',
                name: 'graphicSource',
                component: graphicSource,
            },
            {
                path: '/themeLibrary',
                name: 'themeLibrary',
                component: themeLibrary,
            },
            {
                path: '/imageSource',
                name: 'imageSource',
                component: imageSource,
            },
            {
                path: '/sourceList',
                name: 'sourceList',
                component: sourceList,
            },
            {
                path: '/modelSource',
                name: 'modelSource',
                component: modelSource,
            },
            {
                path: '/videoSource',
                name: 'videoSource',
                component: videoSource,
            },
            {
                path: '/search',
                name: 'search',
                component: search,
            },
        ]
    },
    {
        path: '/user',
        component: UserLayout,
        redirect: '/user/login',
        hidden: true,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "user" */ '@/components/login')
            }
        ]
    },
]
const router = new VueRouter({
    routes
})

function jumpToLogin(to, from, next) {
    if (from.path === '/user/login') {
        window.location.reload();
    } else {
        next({path: '/user/login', query: {redirect: to.fullPath}})
    }
}

router.beforeEach((to, from, next) => {
    if (to.name === 'themeLibrary') {
        let id = to.query.resRepoId;
        getResFileList({resRepoId: id}).then(res => {
            let {code,message} = res.data;
            if (code === -1) {
                if(message==="用户无该主题库权限") {
                    Vue.prototype.$notification['error']({
                        message: '用户无该主题库权限！',
                        duration: 4
                    })
                } else {
                    jumpToLogin(to, from, next);
                }
            } else {
                next()
            }
        }).catch(err => {
            jumpToLogin(to, from, next);
        })
    } else if (to.name === 'graphicSource') {
        let id = to.query.id;
        resFileViewLog(id).then(res => {
            let code = res.data.code;
            console.log(res)
            if (code === -1) {
                jumpToLogin(to, from, next);
            } else {
                next()
            }
        }).catch(err => {
            jumpToLogin(to, from, next);
        })
    } else {
        next()
    }
    return false;
})

export default router
