<template>
  <div class="z-switch">
    <div :class="['e__switch',{'e_is-checked':value}]">
      <span class="e__switch-core" @click="toggle"></span>
    </div>
    <div class="text"  @click="toggle">切换{{value ?'卡片':'地图'}}显示</div>
  </div>
</template>

<script>
export default {
  name: 'e-switch',
  props:['value'],
  methods:{
    toggle(){
      this.$emit('change',!this.value);
      this.$emit('input',!this.value);
    }
  }
}
</script>

<style scoped>
.z-switch{
  float: right;
  width: 150px;
  height: 100%;
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text{
  color: #812b2d;
  margin-top: 2px;
  cursor: pointer;
}
.e__switch {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
  cursor: pointer;
}

.e__switch>input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
}

.e__switch-core {
  margin: 0;
  position: relative;
  width: 60px;
  height: 30px;
  border: 1px solid #DCDFE6;
  outline: 0;
  border-radius: 15px;
  box-sizing: border-box;
  background: #DCDFE6;
  transition: border-color .3s, background-color .3s;
  vertical-align: middle;
}

.e__switch-core::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  transition: all .3s;
  width: 24px;
  height: 24px;
  background-color: #FFF;
}

.e__switch.e_is-checked .e__switch-core {
  border-color: #812b2d;
  background-color: #812b2d;
}

.e__switch.e_is-checked .e__switch-core::after {
  left: 100%;
  margin-left: -26px;
}
</style>
