<template>
  <div class="footer">
    <div>
        <div class="footer-info">
            <div class="footer-wechat">
                <div class="footer-wechat-image-div">
                    <img class="footer-wechat-image" src="../style/img/footer-wechat/wechat.png">
                </div>
                <div class="footer-wechat-message">
                    微信公众号
                </div>
            </div>
            <div class="footer-text-info">
                <div class="footer-text-info-first-line">
                    联系方式:
                </div>
                <div class="footer-text-info-second-line">
                    <div class="footer-text-info-support-hotline">
                        咨询电话：{{systemSetting.contactPhone || '暂无'}}
                    </div>
                    <div class="footer-text-info-email">
                        电子邮箱：{{systemSetting.contactEmail|| '暂无'}}
                    </div>
                    <div class="footer-text-info-address">
                        地址：{{ systemSetting.contactAddress || '暂无'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-line">
            <img src="../style/img/footer-image/footer-line.png" alt="">
        </div>
        <div class="footer-record">
<!--            备案号备案号备案号备案号备案号备案号备案-->
        </div>
    </div>
</div>
</template>

<script>
import {getSystemsetting} from "@/api/content.js";

export default {
  data(){
    return {
      systemSetting: {
        contactAddress:"",
        contactEmail:"",
        contactPhone:"",
      }
    }
  },
  mounted() {
    getSystemsetting().then(e=>{
      this.systemSetting = e.data.result.systemSetting
    })
  }
}
</script>

<style>

</style>
