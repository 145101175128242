var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav-log"},[_c('div',{staticClass:"nav-log-home"},[_vm._m(0),_c('div',{staticClass:"nav-log-home-title pointer",on:{"click":function($event){return _vm.$router.push({name:'content'})}}},[_vm._v(" 首页 ")])]),_vm._m(1),_c('div',{staticClass:"nav-log-middle"},[_vm._v(" 良渚遗址主题库 ")]),_vm._m(2),_c('div',{staticClass:"nav-log-current"},[_vm._v(" 资源预览 ")])]),_c('div',{staticClass:"source-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_vm._m(3),_c('div',{staticClass:"source-time"},[_vm._v(" 2022年10月17日 10:30 ")]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-home-image-div"},[_c('img',{staticClass:"nav-log-home-image",attrs:{"src":require("../style/img/home-logo/home-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-fh"},[_c('img',{staticClass:"nav-log-arrow",attrs:{"src":require("../style/img/arrow-red/arrow-red.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-fh"},[_c('img',{staticClass:"nav-log-arrow",attrs:{"src":require("../style/img/arrow-gray/arrow-gray.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-tag-list"},[_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-content"},[_c('img',{staticClass:"image-source",attrs:{"src":require("../style/img/graphicSource/位图.png")}}),_c('img',{staticClass:"image-source",attrs:{"src":require("../style/img/graphicSource/位图(1).png")}}),_c('img',{staticClass:"image-source",attrs:{"src":require("../style/img/graphicSource/位图(2).png")}}),_c('img',{staticClass:"image-source",attrs:{"src":require("../style/img/graphicSource/位图(3).png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-download"},[_c('div',{staticClass:"source-download-item"},[_c('img',{staticClass:"image-logo"}),_c('div',{staticClass:"source-detail-info"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"main-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_c('div',{staticClass:"version-title"},[_vm._v(" (预览版) ")])]),_c('div',{staticClass:"type"},[_vm._v(" 文件格式：max ")]),_c('div',{staticClass:"size"},[_vm._v(" 文件大小：18.78MB ")])]),_c('div',{staticClass:"source-download-button"},[_c('img',{attrs:{"src":require("../style/img/source-download-logo/source-download-logo.png")}}),_c('div',{staticClass:"text"},[_vm._v(" 下载资源 ")])])]),_c('div',{staticClass:"source-download-item source-download-item-next"},[_c('img',{staticClass:"image-logo"}),_c('div',{staticClass:"source-detail-info"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"main-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_c('div',{staticClass:"version-title"},[_vm._v(" (储存版本） ")])]),_c('div',{staticClass:"type"},[_vm._v(" 文件格式：max ")]),_c('div',{staticClass:"size"},[_vm._v(" 文件大小：18.78MB ")])]),_c('div',{staticClass:"source-download-button"},[_c('img',{attrs:{"src":require("../style/img/source-download-logo/source-download-logo.png")}}),_c('div',{staticClass:"text"},[_vm._v(" 下载资源 ")])])])])
}]

export { render, staticRenderFns }