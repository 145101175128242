class ZMap {
    constructor(container, infoWindow, showFn) {
        //地图对象
        this.map = new AMap.Map(container, {
            resizeEnable: true,
            center: [121.549792, 29.868388],
            zoom: 10
        });
        //点聚合对象
        // this.cluster = null;
        //地图信息窗体
        this.InfoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(20, -50),
            content: infoWindow,
            // isCustom: true
        })
        this.showFn = showFn
    }

    setCluster(list) {
        let points = []
        if (list != null) {
            list.forEach(({lng, lat, name, id, ext, intro, link}) => {
                if (lng && lat && name) {
                    ext = this.getExt(ext);
                    let point = new AMap.Marker({
                        icon: ext != null ? `/icon/${ext}-punctuation.png` : "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                        position: [lng, lat],
                        offset: new AMap.Pixel(-25, -60)
                    });
                    if (this.showFn != null) {
                        point.on('click', (e) => {
                            let fileIcon = ext != null ? `/icon/${ext}.png` : "/icon/unknown.png";
                            this.showFn({id: id, name: name, icon: fileIcon, intro: intro, link: link})
                            this.InfoWindow.open(this.map,e.target.getPosition())
                        })
                    }
                    points.push(point)
                }
            })
        }
        this.map.add(points)
    }

    //移除所有聚合点it
    removeClusterPoints() {
        this.InfoWindow.close()
        this.map.clearMap();
    }

    getExt(ext) {

        // 定义后缀数组
      const videoList = ['mp4','mp3']
      const imgList = ['png', 'jpeg', 'jpg','gif']
      const modelList = ['stl', 'obj', 'glb', 'gltf', 'ply', 'step']
      const pdfList = ['pdf']
      const wordList = ['docx','word','doc']
      const excelList = ['excel','xlsx']
      const pptList = ['ppt','pptx']
      const xmlList = ['xml']
      const txtList = ['txt']
      const mdList = ['md']

      if (videoList.includes(ext)) {
        return 'mp4'
      }
      if (imgList.includes(ext)) {
        return 'png'
      }
      if (modelList.includes(ext)) {
        return 'stl'
      }
      if (pdfList.includes(ext)) {
        return 'pdf'
      }
      if (wordList.includes(ext)) {
        return 'docx'
      }
      if (excelList.includes(ext)) {
        return 'excel'
      }
      if (pptList.includes(ext)) {
        return 'ppt'
      }
      if (xmlList.includes(ext)) {
        return 'xml'
      }
      if (txtList.includes(ext)) {
        return 'txt'
      }
      if (mdList.includes(ext)) {
        return 'md'
      }
        if (videoList.includes(ext)) {
            return 'mp4'
        }
      return 'link'
    }
}

export default ZMap
