<template>
    <div class="mask">
        <div class="container">
            <el-breadcrumb class="nav-log" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <!--        <el-breadcrumb-item v-if="query.resRepoId" replace :to="{ path: '/themeLibrary',query:{resRepoId:query.resRepoId,resName:query.resName} }" >{{ query.resName }}</el-breadcrumb-item>-->
                <!--        <el-breadcrumb-item v-if="query.tagId" replace :to="{ path: '/sourceList',query:{tagId:query.tagId,tagName:query.tagName} }" >{{ query.tagName }}</el-breadcrumb-item>-->
                <el-breadcrumb-item>{{ $route.query.resName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="theme-library-top">
                <div class="theme-library-top-nav">
                    <div class="pointer theme-library-top-nav-block" v-for="item in tagList" :key="item.id"
                         @click="toSourceList(item)">
                        {{ item.name }}
                    </div>
                    <!-- <div class="pointer theme-library-top-nav-block theme-library-top-nav-block-select" @click="toSourceList">
                        文博学刊
                    </div>
                    <div class="theme-library-top-nav-block">
                        文博学刊
                    </div>
                    <div class="theme-library-top-nav-block">
                        学术咨询
                    </div>
                    <div class="theme-library-top-nav-block">
                        科研课题
                    </div>
                    <div class="theme-library-top-nav-block">
                        历史文物
                    </div>
                    <div class="theme-library-top-nav-block">
                        艺术与民俗
                    </div> -->
                </div>
                <div class="theme-library-top-left">
                    <div class="theme-library-top-banner">
                        <el-carousel height="380px">
                            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
                                <img :src="item.imgUrl" style="width:100%;height:100%;">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="theme-library-top-statistic">
                        <img class="theme-library-top-statistic-cloud-left"
                             src="../style/img/theme-library-top-statistic-cloud-left/theme-library-top-statistic-cloud-left.png">
                        <div class="theme-library-top-statistic-title">
                            资源数量
                        </div>
                        <img class="theme-library-top-statistic-cloud-right"
                             src="../style/img/theme-library-top-statistic-cloud-right/theme-library-top-statistic-cloud-right.png">

                        <div class="theme-library-top-statistic-graphic-number">
                            {{ total.twTotal }}
                        </div>
                        <div class="theme-library-top-statistic-graphic-title">
                            【图片总数】
                        </div>
                        <div class="theme-library-top-statistic-video-number">
                            {{ total.spTotal }}
                        </div>
                        <div class="theme-library-top-statistic-video-title">
                            【视频总数】
                        </div>
                        <div class="theme-library-top-statistic-model-number">
                            {{ total.mxTotal }}
                        </div>
                        <div class="theme-library-top-statistic-model-title">
                            【模型总数】
                        </div>
                    </div>
                </div>
            </div>
            <div class="theme-library-source-nav">
                <div class="theme-library-source-nav-block pointer"
                     :class="{'theme-library-source-nav-block-select':activeNavIndex===item.index}"
                     v-for="item in navList" :key="item.name" @click="clickNavItem(item)">
                    {{ item.name }}
                </div>
                <zSwitch v-model="mapShow"/>
                <div class="theme-library-source-nav-order">
                    <div @click="sort=0" style="cursor: pointer;" class="theme-library-source-nav-order-complex"
                         :class="{'theme-library-source-nav-order-select':sort===0}">
                        综合
                    </div>
                    <div class="theme-library-source-nav-order-fh"></div>
                    <div @click="sort=1" style="cursor: pointer;" class="theme-library-source-nav-order-new"
                         :class="{'theme-library-source-nav-order-select':sort===1}">
                        最新
                    </div>
                </div>

            </div>

            <div v-if="!mapShow" class="theme-library-source-list" style="min-height:500px">
                <div class="theme-library-source-list-line" v-if="resFileList.length>=1">
                    <div class="theme-library-sourece-list-block pointer mr-16 mt-16"
                         v-for="(item,index) in resFileList" :key="index" @click='detail(item)'>
                        <div class="theme-library-sourece-list-image-div">
                            <img class="theme-library-sourece-list-image" :src="item.cover">
                            <div class="theme-library-sourece-list-source-type">
                                <div class="message">
                                    {{ item.resFileTypeName }}
                                </div>
                            </div>
                        </div>
                        <div class="theme-library-sourece-list-title">
                            {{ item.name }}
                        </div>
                        <div class="theme-library-sourece-list-caption">
                            {{ item.intro }}
                            <div class="theme-library-sourece-list-caption-view-times">
                                访问量: {{item.viewTimes}}
                            </div>
                        </div>
                        <div class="theme-library-sourece-list-info">
                            {{ item.ndInfo }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <el-empty description="暂无数据"></el-empty>
                </div>


            </div>
            <!-- 分页 -->
            <div v-if="!mapShow" class="block" style="text-align:center;">
                <el-pagination v-show="pages.total"
                               @current-change="handleCurrentChange"
                               :page-size="12"
                               layout="total, prev, pager, next"
                               :total="pages.total">
                </el-pagination>
            </div>
            <div v-show="mapShow" style="width: 100%;height: 600px;margin: 20px 0" id="container" class="map"></div>
            <div class="footer-nav">
                <div v-for="(item,i) in bttmExtLinkList" :class="[(i%2) ? 'footer-nav-map':'footer-nav-genealogy']">
                    <div class="footer-nav-title">
                    </div>
                    <div class="footer-nav-caption">
                    </div>
                    <a target="_blank" style="display: block;text-decoration:none" :href="item.url"
                       class="footer-nav-entrance">
                        点击进入
                    </a>
                </div>
            </div>
        </div>
        <el-dialog title="图片弹框" :visible.sync="imgDialogVisible">
            <div style="text-align:center;">
                <img class="image-source" style="width:90%;" :src="imgSrc"/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="imgDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="视频弹框" :visible.sync="videoDialogVisible">
            <div style="text-align:center;">
                <video
                        :src="videoSrc"
                        alt="预览"
                        ref="video"
                        id="video"
                        controls="controls"
                        width="100%"
                        height="400px"
                />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="videoDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="模型弹框" :visible.sync="modelDialogVisible">
            <div style="text-align:center;">
                <img class="image-source" style="width:90%;" :src="modelSrc"/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="modelDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <div style="display: none;">
            <zMpInfoWindow ref="infoWindow" :info="mapPannelInfo"></zMpInfoWindow>
        </div>

        <my-iframe
            ref="myIframe"
            :visible="myFrameVisible"
            :url="frameUrl"
            @close="frameClose"
        />
    </div>
</template>

<script>
import zSwitch from "@/components/zSwitch.vue";
import {getResDetailData, getResFileAllList, getResFileList, resFileViewLog, saveViewTime} from '../api/content.js'
import zMap from '../utils/zMap.js'
import zMpInfoWindow from './zMpInfoWindow';
import MyIframe from "@/components/myIframe.vue";

export default {
    components: {MyIframe, zSwitch, zMpInfoWindow},
    data() {
        return {
            mapPannelInfo: {
                id: '',
                name: '',
                icon: '',
                intro: '',
            },
            bttmExtLinkList: [],
            imgDialogVisible: false,
            imgSrc: '',
            videoDialogVisible: false,
            videoSrc: '',
            modelDialogVisible: false,
            modelSrc: '',
            lights: [{
                type: 'HemisphereLight',
                position: {x: 0, y: 1, z: 0},
                skyColor: 0xffffff,
                // groundColor: 0xFF0000, // 此代码为灯光后颜色
                intensity: 1,
            },
                {
                    type: 'DirectionalLight',
                    position: {x: 1, y: 1, z: 1},
                    color: 0xffffff,
                    intensity: .8,
                }],
            resRepoId: '',
            tagList: [],
            carouselList: [],
            total: {
                twTotal: '',
                spTotal: '',
                mxTotal: ''
            },
            resFileList: [],
            pages: {
                currentTotal: 1,
                total: 0,
                pageNo: 1
            },
            resFileType: undefined,
            navList: [
                {name: '全部资源', index: 0, value: null},
                {name: '图文', index: 1, value: 'tw'},
                {name: '视频', index: 2, value: 'sp'},
                {name: '图片', index: 3, value: 'tp'},
                {name: '模型', index: 4, value: 'mx'},
                {name: '其他', index: 5, value: 'link'}
            ],
            activeNavIndex: 0,
            sort: 0,
            // sourceList:[
            //     {bgPath:require('../style/img/themeLibrary/tw.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图文',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/tp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图片',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/sp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'视频',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/mx.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'模型',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'}
            // ],
            // sourceList1:[
            //     {bgPath:require('../style/img/themeLibrary/tp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图片',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/tw.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图文',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/mx.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'模型',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/sp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'视频',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'}
            // ],
            // sourceList2:[
            //     {bgPath:require('../style/img/themeLibrary/sp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'视频',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/mx.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'模型',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/tw.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图文',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/tp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图片',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'}
            // ],
            // sourceList3:[
            //     {bgPath:require('../style/img/themeLibrary/tp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图片',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/mx.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'模型',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/tw.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'图文',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'},
            //     {bgPath:require('../style/img/themeLibrary/sp.png'),logoPath:require('../style/img/theme-library-sourece-type-image-logo/theme-library-sourece-type-image-logo.png'),title:'视频',content1:'良渚遗址是中华五千年文明的实证',content2:'伟大的城市，伟大的文明',content3:'距今5300-4000年 长江下游'}
            // ]
            zMap: null,
            mapShow: false,
            myFrameVisible: false,
            frameUrl: '',
            resFileId: -1
        }
    },
    watch: {
        sort() {
            this.getResFile()
        }
    },
    created() {
        this.resRepoId = this.$route.query.resRepoId
        this.getResData()
        this.getResFile()
    },
    mounted() {
        this.zMap = new zMap('container', this.$refs.infoWindow.$el, this.showMapInfo)
    },
    methods: {
        frameClose(data){
          this.myFrameVisible = false
          // console.log(`共学习${data}s`)
          saveViewTime({resFileId:this.resFileId,viewTime:data}).then(res=>{
            // console.log(res)
          })
        },
        showMapInfo(info) {
            // 更新面板信息
            this.mapPannelInfo = info
        },
        jumpToLongin() {
            this.$notification['error']({
                message: '该资源需要登录后访问！',
                // description: description,
                duration: 4
            })
            setTimeout(() => {
                this.$router.replace({path: '/user/login', query: {redirect: this.$route.fullPath}})
            }, 0)
        },
        getResFile() {
            let base_params = {resRepoId: this.resRepoId, resFileType: this.resFileType}
            getResFileList({
                ...base_params,
                pageNo: this.pages.pageNo,
                pageSize: 12,
                zhpx: this.sort === 0,
            }).then(res => {
                let code = res.data.code;
                if (code === -1) {
                    this.jumpToLongin();
                    return
                }
                this.resFileList = res.data.result.list;
                this.pages.total = res.data.result.total
            }).catch(err => {
                // console.log(err);
            })
            getResFileAllList(base_params).then(e => {
                this.zMap.removeClusterPoints()
                this.zMap.setCluster(e.data.result)
            })
        },
        getResData() {
            getResDetailData({resRepoId: this.resRepoId}).then(res => {
                let code = res.data.code;
                console.log(code)
                if (code === -1) {
                    this.jumpToLongin();
                    return
                }
                let result = res.data.result;
                this.tagList = result.tagList
                this.carouselList = result.carouselList
                this.total.twTotal = result.twTotal
                this.total.spTotal = result.spTotal
                this.total.mxTotal = result.mxTotal
                this.bttmExtLinkList = result.bttmExtLinkList
            }).catch(err => {
                console.log(err);
            })
        },
        handleCurrentChange(page) {
            this.pages.pageNo = page
            this.getResFile()
        },
        clickNavItem(item) {
            this.activeNavIndex = item.index
            this.resFileType = item.value
            this.getResFile()
        },
        handleClose(done) {
            done()
        },
        async detail(item) {
          // 目前访问资源为弹窗形式，将写入记录访问提出来
          const {data:{code}} = await resFileViewLog(item.id)
          this.resFileId = item.id
          if(item.resFileType ==='link'){
            // const {data:{code}} = await resFileViewLog(item.id)
            if(code===-1){
              this.$notification['error']({
                message: '该资源需要登录后访问！',
                // description: description,
                duration: 4
              })
              setTimeout(()=>{
                this.$router.replace({ path: '/user/login',query:{redirect:this.$route.fullPath}})
              },0)
              return
            } else {
              // window.open(item.link,'_blank')
              this.frameUrl = item.link
              this.myFrameVisible = true
            }
            return
          }
          // let routeData = this.$router.resolve({path: '/graphicSource', query: {id: item.id, ...this.$route.query}})
          // window.open(routeData.href, '_blank');
          this.frameUrl = item.url
          this.myFrameVisible = true
        },
        toSourceList(item) {
            console.log(item)
            this.$router.push({name: 'sourceList', query: {tagId: item.id, tagName: item.name}})
        },

    }
}
</script>

<style scoped>
.pointer {
    cursor: pointer;
}

.mr-16 {
    margin-right: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.theme-library-top-nav-block:hover {
    background: rgba(66, 67, 72, 0.3);
}
</style>
