import axios from 'axios'

const instance = axios.create({
    baseURL: "/api",
    timeout: 60000,
    headers: {
    }
});

export default instance
