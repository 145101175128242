<template>
    <div class="z-map-info-window-div" @click="jumpToDetail">
        <img :src="info.icon" alt="">
        <div class="text-info">
            <div class="name">{{ info.name }}</div>
            <div class="intro">
                {{ info.intro }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'z-map-info-window',
    props: ['info'],
    methods: {
        jumpToDetail() {
            if (this.info.link != null && this.info.link !== '') {
                window.open(this.info.link, "_blank")
            } else {
                let routeUrl = this.$router.resolve({
                    path: '/graphicSource', query: {id: this.info.id}
                });
                window.open(routeUrl.href, '_blank');
            }
        }
    }
}
</script>
<style scoped>
.z-map-info-window-div {
    background-color: white;
    border-radius: 25px;
    cursor: pointer;
}

img {
    height: 20px;
    display: inline-block;
    vertical-align: top;
}

.text-info {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}

.text-info .intro {
    color: gray;
    font-size: 12px;
}
</style>
