<template>
  <div class="container">
    <el-breadcrumb class="nav-log" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <!--        <el-breadcrumb-item v-if="query.resRepoId" replace :to="{ path: '/themeLibrary',query:{resRepoId:query.resRepoId,resName:query.resName} }" >{{ query.resName }}</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item v-if="query.tagId" replace :to="{ path: '/sourceList',query:{tagId:query.tagId,tagName:query.tagName} }" >{{ query.tagName }}</el-breadcrumb-item>-->
      <el-breadcrumb-item> 搜索结果 </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- tab切换 -->
    <div class="theme-library-source-nav">
            <div class="theme-library-source-nav-block pointer" :class="{'theme-library-source-nav-block-select':activeNavIndex===item.index}" v-for="item in navList" :key="item.name" @click="clickNavItem(item)">
                {{item.name}}
            </div>
      <zSwitch v-model="mapShow" />
    <div class="theme-library-source-nav-order">
      <div @click="sort=0"  style="cursor: pointer;" class="theme-library-source-nav-order-complex" :class="{'theme-library-source-nav-order-select':sort===0}">
        综合
      </div>
      <div class="theme-library-source-nav-order-fh"></div>
      <div @click="sort=1"  style="cursor: pointer;" class="theme-library-source-nav-order-new" :class="{'theme-library-source-nav-order-select':sort===1}">
        最新
      </div>
    </div>
        </div>
    <div v-show="!mapShow" class="theme-library-source-list" style="min-height:500px">
            <div class="theme-library-source-list-line">
                <div class="theme-library-sourece-list-block pointer" style="margin-left: 10px;margin-bottom: 16px" v-for="(item,index) in resFileList" :key="index" @click='detail(item)'>
                    <div class="theme-library-sourece-list-image-div">
                        <img class="theme-library-sourece-list-image" :src="item.cover">
                        <div class="theme-library-sourece-list-source-type">
                            <div class="message">
                                {{item.resFileTypeName}}
                            </div>
                        </div>
                    </div>
                    <div class="theme-library-sourece-list-title">
                        {{item.name}}
                    </div>
                    <div class="theme-library-sourece-list-caption">
                        {{item.intro}}
                    </div>
                    <div class="theme-library-sourece-list-info">
                        {{item.ndInfo}}
                    </div>
                </div>
            </div>
        </div>
          <div v-show="!mapShow" class="block" style="text-align:center;margin-bottom: 16px">
                <el-pagination
                @current-change="handleCurrentChange"
                :page-size="12"
                layout="total, prev, pager, next"
                :total="pages.total">
                </el-pagination>
            </div>

    <div v-show="mapShow" style="width: 100%;height: 600px;margin: 20px 0" id="container" class="map" ></div>
    <div style="display: none;">
      <zMpInfoWindow ref="infoWindow" :info="mapPannelInfo"></zMpInfoWindow>
    </div>
  </div>
</template>

<script>
import {getResFileAllList, getResFileList} from '../api/content.js'
import zSwitch from "@/components/zSwitch.vue";
import zMap from '../utils/zMap.js'
import zMpInfoWindow from './zMpInfoWindow';
export default {
    name:'search',
    components:{zSwitch,zMpInfoWindow},
    data(){
        return{
          mapPannelInfo: {
            id: '',
            name: '',
            icon: '',
            intro: '',
          },
          sort:0,
            pages:{
                currentTotal:1,
                total:0,
                pageNo:1
            },
            navList:[
                {name:'全部资源',index:0},
                {name:'图文',index:1,value:'tw'},
                {name:'视频',index:2,value:'sp'},
                {name:'图片',index:3,value:'tp'},
                {name:'模型',index:4,value:'mx'},
                {name:'其他',index:5,value:'link'}
            ],
            activeNavIndex:0,
            resFileType:undefined,
            searchKey:undefined,
            resFileList:[],

            zMap:null,
            mapShow:false
        }
    },
    watch:{
      '$route.query': {
        handler({searchKey}) {
          console.log(searchKey)
          this.searchKey = searchKey || ''
          this.getResFile()
        },
      },
      sort(){
        this.getResFile()
      }
    },
    created(){

        this.searchKey = this.$route.query.searchKey
        this.getResFile()
    },
  mounted() {
    // this.zMap = new zMap('container')
    this.zMap = new zMap('container', this.$refs.infoWindow.$el, this.showMapInfo)

  },
  methods:{
        getResFile(){
          let base_params = {resFileType:this.resFileType,name:this.searchKey}
            getResFileList({...base_params,pageNo:this.pages.pageNo,pageSize:12,zhpx: this.sort ===0}).then(res=>{
                this.resFileList = res.data.result.list;
                this.pages.total=res.data.result.total
            }).catch(err=>{
                console.log(err);
            })

          getResFileAllList(base_params).then(e=>{
            this.zMap.removeClusterPoints()
            this.zMap.setCluster(e.data.result)
          })
        },
        clickNavItem(item){
            this.activeNavIndex = item.index
            this.resFileType = item.value
            this.getResFile()
        },
        handleCurrentChange(page){
            this.pages.pageNo = page
            this.getResFile()
        },
        showMapInfo(info) {
          // 更新面板信息
          this.mapPannelInfo = info
        },
        detail(item){
          if(item.resFileType ==='link'){
            return window.open(item.link)
          }
          // this.$router.push({ path: '/graphicSource', query: { id: item.id,path:`/themeLibrary?resRepoId=${this.resRepoId}&tagName=${tagName}`,tagName } })
          this.$router.push({ path: '/graphicSource', query: { id: item.id } })
        },
    }
}

</script>

<style scoped>
/*.theme-library-sourece-list-block:nth-child(4n+1){*/
/*  margin-left: 0;*/
/*}*/
</style>
