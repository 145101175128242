import axios from '../utils/request'

function auth(url){
    return axios.post(url,{},{
        headers: {'token':localStorage.getItem('whyc_token') }
    })
}

export function saveViewTime(params){
    return axios.put('/h5/resFile/viewLog',params,{
        headers: {'token':localStorage.getItem('whyc_token') }
    })
}

export function getTopList(params){
    return axios({
        headers: {'token':localStorage.getItem('whyc_token') },
        url:'/h5/indexTop',
        methods:'get',
        params
    })
}
export function getResPoList(params){
    return axios({
        headers: {'token':localStorage.getItem('whyc_token') },
        url:'/h5/resRepo/list',
        methods:'get',
        params
    })
}
export function getResDetailData(params){
    return axios({
        headers: {'token':localStorage.getItem('whyc_token') },
        url:'/h5/resRepo/top',
        methods:'get',
        params
    })
}

export function getResFileList(params){
    return axios({
        headers: {'token':localStorage.getItem('whyc_token') },
        url:'/h5/resFile/list',
        methods:'get',
        params
    })
}
export function getResFileAllList(params){
    return axios({
        url:'/h5/resFile/listAll',
        methods:'get',
        params
    })
}


export function getResFileDetail(id){
    return axios({
        url:`/api/resFile/detail/${id}`,
        methods:'get',
    })
}

export function getSystemsetting(){
    return axios({
        url:`/h5/systemsetting/coInfo`,
        methods:'get',
    })
}

export function userLogin(data){
    return axios.post(`/h5/user/login`,data)
}

export function resFileViewLog(id){
    return auth(`/h5/resFile/viewLog?id=${id}`)
}

