<template>
  <div>
        <div class="container">
        <div class="nav-log">
            <div class="nav-log-home">
                <div class="nav-log-home-image-div">
                    <img class="nav-log-home-image" src="../style/img/home-logo/home-logo.png" />
                </div>
                <div class="nav-log-home-title pointer" @click="$router.push({name:'content'})">
                    首页
                </div>
            </div>
            <div class="nav-log-fh">
                <img class="nav-log-arrow" src="../style/img/arrow-red/arrow-red.png">
            </div>
            <div class="nav-log-middle">
                良渚遗址主题库
            </div>
            <div class="nav-log-fh">
                <img class="nav-log-arrow" src="../style/img/arrow-gray/arrow-gray.png">
            </div>
            <div class="nav-log-current">
                资源预览
            </div>
        </div>

        <div class="source-title">
            良渚遗址是中华五千年文明的实证？
        </div>
        <div class="source-tag-list">
            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>
        </div>
        <div class="source-time">
            2022年10月17日 10:30
        </div>
        <div class="source-content">
            <video src="../style/video/tmp/t.mp4"></video>
            <div class="source-video-content">
                <img class="play-button"/>
            </div>
        </div>
  
        <div class="source-download">
            <div class="source-download-item">
                <img class="video-logo">
                <div class="source-detail-info">
                    <div class="title">
                        <div class="main-title">
                            良渚遗址是中华五千年文明的实证？
                        </div>
                        <div class="version-title">
                            (预览版)
                        </div>
                    </div>
                    <div class="type">
                        文件格式：max
                    </div>
                    <div class="size">
                        文件大小：18.78MB
                    </div>
                </div>
                <div class="source-download-button">
                    <img src="../style/img/source-download-logo/source-download-logo.png"/>
                    <div class="text">
                        下载资源
                    </div>
                </div>
            </div>

            <div class="source-download-item source-download-item-next">
                <img class="video-logo">
                <div class="source-detail-info">
                    <div class="title">
                        <div class="main-title">
                            良渚遗址是中华五千年文明的实证？
                        </div>
                        <div class="version-title">
                            (储存版本）
                        </div>
                    </div>
                    <div class="type">
                        文件格式：max
                    </div>
                    <div class="size">
                        文件大小：18.78MB
                    </div>
                </div>
                <div class="source-download-button">
                    <img src="../style/img/source-download-logo/source-download-logo.png"/>
                    <div class="text">
                        下载资源
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>