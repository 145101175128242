<template>
  <div class="content-box" v-if="visible">
    <div id="my-frame-content"  class="content">
      <div id="frame-box" class="frame-box">
        <iframe :src="url" allowfullscreen />
      </div>
      <div class="footer-box">
        <el-row>
          <el-col :span="10" style="text-align: right">
            <div style="line-height: 40px">本次已学{{times}}s</div>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <el-button @click="closed()" type="primary">完成学习</el-button>
          </el-col>
          <el-col :span="10" />
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myIframe",
  props: {
    url: String,
    visible: Boolean
  },
  data() {
    return {
      mouseIn: false,
      step: 0,
      times: 0,
      timer: undefined,
      subFlag: false,
      visibilityState: true,
      isInFrame: true
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.canScroll()
  },
  mounted() {
    window.addEventListener('beforeunload', () => {
      if(!this.subFlag){
        this.closed()
      }
    });
  },
  watch: {
    visible(data) {
      if(data){
        this.stopScroll()
        this.subFlag = false
        this.$nextTick(()=>{
          let element = document.getElementById("my-frame-content");
          if(element){
            element.addEventListener('mouseover', ()=>{
              this.isInFrame = true
              this.step = this.visibilityState ? 1: 0
            })

            document.addEventListener('visibilitychange', () => {
              if (document.visibilityState === 'hidden') {
                this.visibilityState = false
                this.step = 0;
              } else if (document.visibilityState === 'visible') {
                this.visibilityState = true
                if(this.isInFrame){
                  this.step = 1;
                }
              }
            });


            element.addEventListener('mouseout', ()=> {
              this.isInFrame = false
              this.step = 0
            })

            this.timer = setInterval(() => {
              this.times += this.step
              // console.log(this.times)
            }, 1000)
          }
        })
      }else{
        this.canScroll()
        clearInterval(this.timer)
      }
    }
  },
  methods: {
    closed(){
      clearInterval(this.timer)
      this.subFlag = true
      this.$emit('close',this.times)
      this.times = 0
    },

    stopScroll(){
      let mo=function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",mo,false);
    },

    canScroll(){
      let mo=function(e){e.preventDefault();};
      document.body.style.overflow='';
      document.removeEventListener("touchmove",mo,false);

    }
  }
}
</script>

<style scoped lang="less">
body{
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.content-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255,255,255,0.7);
  top: 0;
  left: 0;

  .content {
    width: 100%;
    height: 100%;
    background: white;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);


    .frame-box {
      width: 100%;
      height: calc(100% - 60px);

      iframe {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        object-fit: cover;
        border-radius: 20px 20px 0 0;
      }
    }

    .footer-box {
      margin: 10px auto;
      border-radius: 0 0 20px 20px;
    }
  }
}
</style>