<template>
  <div id="app">
    <KeepAlive>
    <router-view ></router-view>
    </KeepAlive>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
#app{
  height: 100%;
}
.is-link:hover {
  color: #000000 !important;
  cursor: pointer;
}
.el-pager li.active{
  color: #722b28 !important;
}
.el-pager li:hover {
  color: #000000 !important;
}

.nav-log{
  display: none !important;
}
</style>
