<template>
  <div>
        <div class="container">
        <div class="nav-log">
            <div class="nav-log-home">
                <div class="nav-log-home-image-div">
                    <img class="nav-log-home-image" src="../style/img/home-logo/home-logo.png" />
                </div>
                <div class="nav-log-home-title pointer" @click="$router.push({name:'content'})">
                    首页
                </div>
            </div>
            <div class="nav-log-fh">
                <img class="nav-log-arrow" src="../style/img/arrow-red/arrow-red.png">
            </div>
            <div class="nav-log-middle">
                良渚遗址主题库
            </div>
            <div class="nav-log-fh">
                <img class="nav-log-arrow" src="../style/img/arrow-gray/arrow-gray.png">
            </div>
            <div class="nav-log-current">
                资源预览
            </div>
        </div>

        <div class="source-title">
            良渚遗址是中华五千年文明的实证？
        </div>
        <div class="source-tag-list">
            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>

            <div class="source-tag">
                距今5300-4000年 长江下游
            </div>

            <div class="source-tag">
                考古类
            </div>
        </div>
        <div class="source-time">
            2022年10月17日 10:30
        </div>
        <div class="source-text1">
            “打脸来得太快，就像龙卷风”不止适用于酷爱立人设的娱乐圈，历史界也屡试不爽。一直以来，西方学者都认为中华文明只始于距今3500年前后的殷商时期，没想到今年7月份“良渚古城遗址”就被联合国教科文组织列入了《世界遗产名录》。这意味着什么？
                <br><br> 
            中国新石器这一被远远低估的时代正清晰地展现在世人面前，诉说着来自五千多年前的文明，它改写了中国历史，更改写了世界历史！        </div>
        <div class="source-content">
            <img class="model-image" src="../style/img/tmp/tmp-model/tmp-model.png"/>
        </div>
        <div class="source-text2">
            80年前的意外惊喜<br><br>
            良渚文明的发现震惊海内外，但很少有人知道为了挖掘出这颗蒙尘的珍珠，竟走过了80个漫长岁月……<br><br>
            良渚，指的是距今5300-4300年期间，在中国南方的长江下游环太湖流域曾经存在过的一个文明，它是迄今发现的中国大地上最早的国家。以良渚古城为核心的遗址就是良渚文明的中心。而关于它的发现，还要回溯到1936年。<br><br>
            当时，浙江省立西湖博物馆的一位馆员施昕更，在对位于古荡的新石器时代遗址进行考古发掘时发现了一件黑陶。他觉得，类似的黑陶在自己的家乡良渚也曾见到过。于是不久后他就回到良渚，进行文物调查。<br><br>
            在他的主持下，良渚陆续出土了黑陶、石器、玉器等数百件文物。这一次“小”发现，正式掀开了良渚遗址的一角。然而，随着次年抗战的爆发，良渚遗址的发掘和研究工作不得不被迫中断。<br><br>
        </div>
        <!-- 查看、收起预览内容 -->
        <div class="preview">
            <div class="ViewingContent">查看剩余预览内容
                <img src="../style/img/tmp/tmp-model/更多.png" alt="">
            </div>
            <div class="putAwayContent">收起预览内容
                <img src="../style/img/tmp/tmp-model/收起.png" alt="">
            </div>
        </div>

        <div class="source-download">
            <div class="source-download-item">
                <img class="model-logo">
                <div class="source-detail-info">
                    <div class="title">
                        <div class="main-title">
                            良渚遗址是中华五千年文明的实证？
                        </div>
                        <div class="version-title">
                            (预览版)
                        </div>
                    </div>
                    <div class="type">
                        文件格式：max
                    </div>
                    <div class="size">
                        文件大小：18.78MB
                    </div>
                </div>
                <div class="source-download-button">
                    <img src="../style/img/source-download-logo/source-download-logo.png"/>
                    <div class="text">
                        下载资源
                    </div>
                </div>
            </div>

            <div class="source-download-item source-download-item-next">
                <img class="model-logo">
                <div class="source-detail-info">
                    <div class="title">
                        <div class="main-title">
                            良渚遗址是中华五千年文明的实证？
                        </div>
                        <div class="version-title">
                            (储存版本）
                        </div>
                    </div>
                    <div class="type">
                        文件格式：max
                    </div>
                    <div class="size">
                        文件大小：18.78MB
                    </div>
                </div>
                <div class="source-download-button">
                    <img src="../style/img/source-download-logo/source-download-logo.png"/>
                    <div class="text">
                        下载资源
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>