<template>
  <div>
    <div class="container">
            <div class="index-top">
                <div class="index-top-nav">
                    <div class="index-top-nav-line" >
                        <span class="index-top-nav-block " style="width:149px;cursor:pointer;"  v-for="item in tagList" :key="item.id" @click="toSourceList(item)">
                            {{item.name}}
                        </span>
                    </div>
                </div>
                <div class="index-top-banner">
                    <div class="index-top-banner-img-box">
                        <el-carousel height="380px" >
                            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
                                <img :src="item.imgUrl">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <div class="index-top-statistic">
                <img class="index-top-statistic-cloud-left" src="../style/img/index-top-statistic-cloud-left/index-top-statistic-cloud-left.png">
                <div class="index-top-statistic-title">
                资源数量
                </div>
                <img class="index-top-statistic-cloud-right" src="../style/img/index-top-statistic-cloud-right/index-top-statistic-cloud-right.png">

                <div class="index-top-statistic-theme-number">
                    {{resRepoTotal}}
                </div>
                <div class="index-top-statistic-theme-title">
                    【主题库总数】
                </div>
                <div class="index-top-statistic-source-number">
                    {{resFileTotal}}
                </div>
                <div class="index-top-statistic-source-title">
                    【资源文件总数】
                </div>
            </div>
            <div class="index-theme-library">
                <div class="index-theme-library-title-line">
                    <div class="index-theme-library-title">
                    主题库
                    </div>
                    <!-- <div class="index-theme-library-title-more">
                    查看更多 >
                    </div> -->
                </div>
                <div class="index-theme-library-list">
                    <div class="index-theme-library-list-line">
                        <div class="index-theme-library-list-block pointer mt-16 mr-16" v-for="(item) in resPoList" :key="item.id" @click="toThemeLibrary(item)">
                            <div class="index-theme-library-list-block-title">
                                {{item.name}}
                            </div>
                            <div class="index-theme-library-list-block-image-div">
                                <img class="index-theme-library-list-block-image" :src="item.cover">
                                <div class="index-theme-library-list-block-source-type">
                                    资源库
                                </div>
                            </div>
                            <div class="index-theme-library-list-block-caption">
                                {{item.intro}}
                            </div>
                            <div class="index-theme-library-list-block-first-line">
                                <div class="index-theme-library-list-block-creater">
                                    创建人: {{item.admin}}
                                </div>
                                <div class="index-theme-library-list-block-source-number">
                                    {{item.fileNum}}个相关资源
                                </div>
                            </div>
                            <div class="index-theme-library-list-block-second-line">
                                <div class="index-theme-library-list-block-create-time">
                                    创建时间：{{item.createDate}}
                                </div>
                                <div  class="index-theme-library-list-block-source-primary-lock">
                                    <img class="index-theme-library-list-block-source-primary-lock-image" src="../style/img/open/open.png">
                                </div>
                            <div class="index-theme-library-list-block-source-primary">
                            {{item.pub===true?'公开库':'私密库'}}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 分页 -->
             <div class="block" style="text-align:center;">
                <el-pagination
                @current-change="handleCurrentChange"
                :page-size="12"
                layout="total, prev, pager, next"
                :total="pages.total">
                </el-pagination>
            </div>
            <div class="footer-nav">
                <div v-for="(item,i) in bttmExtLinkList" :class="[(i%2) ? 'footer-nav-map':'footer-nav-genealogy']">
                  <div :style="{backgroundImage: 'url(' + item.imgUrl + ')' }" style="background-size: 100% 100%;width: 599px;height: 160px">
                    <div class="footer-nav-title" />
                    <div class="footer-nav-caption"/>
                    <a target="_blank" style="display: block;text-decoration:none" :href="item.url" class="footer-nav-entrance">
                      点击进入
                    </a>
                  </div>

                </div>
<!--                <div class="footer-nav-map">-->
<!--                    <div class="footer-nav-title"></div>-->
<!--                    <div class="footer-nav-caption"></div>-->
<!--                    <div class="footer-nav-entrance">-->
<!--                        点击进入-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
  </div>
</template>

<script>
import {getTopList,getResPoList} from '../api/content'
export default {
  name: 'content',
  created(){
    this.getResPoData()
    this.getTopData()
  },
  mounted(){
  },
  data(){
    return {
        tagList:[],
        resFileTotal:'',
        resRepoTotal:'',
        carouselList:[],
        resPoList:[],
      bttmExtLinkList:[],
        pages:{
            currentTotal:1,
            total:0,
            pageNo:1
        }
    }
  },
  methods:{
    toThemeLibrary({id,name,loginView}){
      this.$router.push({name:'themeLibrary',query:{resRepoId:id,resName:name}})
      // if(loginView && localStorage.getItem('whyc_token')) {
      //
      //   return
      // } else {
      //   this.$router.push({name:'themeLibrary',query:{resRepoId:id,resName:name}})
      // }
    },
    toSourceList(item){
        this.$router.push({name:'sourceList',query:{tagId:item.id,tagName:item.name}})
    },
    getTopData(){
        getTopList().then(res=>{
            let result = res.data.result
            this.tagList = result.tagList
            this.carouselList = result.carouselList
            this.resFileTotal = result.resFileTotal
            this.resRepoTotal = result.resRepoTotal
            this.bttmExtLinkList = result.bttmExtLinkList
        }).catch(err=>{
            console.log(err);
        })
    },
    getResPoData(){
        getResPoList({pageNo:this.pages.pageNo,pageSize:12}).then(res=>{
            this.resPoList = res.data.result.list;
            this.pages.total=res.data.result.total
        }).catch(err=>{
            console.log(err);
        })
    },
    handleCurrentChange(page){
        this.pages.pageNo = page
        this.getResPoData()
    }
  }
}
</script>

<style scoped>
.mr-16{
    margin-right: 16px;
}
.mt-16{
    margin-top: 16px;
}
.pointer{
    cursor: pointer;
}
.index-top-nav-block:hover{
    background: rgba(66, 67, 72, 0.3);
}
.index-theme-library-list-block:nth-child(4n){
  margin-right: 0;
}

</style>
