<template>
  <div>
        <div class="container">
          <div class="nav-log"  style="display:flex;justify-content: space-between;align-items: center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item >{{ query.tagName }}</el-breadcrumb-item>
            </el-breadcrumb>
<!--            <div class="theme-library-source-nav-order">-->
<!--              <div @click="sort=0"  style="cursor: pointer;" class="theme-library-source-nav-order-complex" :class="{'theme-library-source-nav-order-select':sort===0}">-->
<!--                综合排序-->
<!--              </div>-->
<!--              <div class="theme-library-source-nav-order-fh"></div>-->
<!--              <div @click="sort=1"  style="cursor: pointer;" class="theme-library-source-nav-order-new" :class="{'theme-library-source-nav-order-select':sort===1}">-->
<!--                最新发布-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="theme-library-source-nav">
            <div class="theme-library-source-nav-block pointer" :class="{'theme-library-source-nav-block-select':activeNavIndex===item.index}" v-for="item in navList" :key="item.name" @click="clickNavItem(item)">
              {{item.name}}
            </div>
            <zSwitch v-model="mapShow" />
            <div class="theme-library-source-nav-order">
              <div @click="sort=0"  style="cursor: pointer;" class="theme-library-source-nav-order-complex" :class="{'theme-library-source-nav-order-select':sort===0}">
                综合
              </div>
              <div class="theme-library-source-nav-order-fh"></div>
              <div @click="sort=1"  style="cursor: pointer;" class="theme-library-source-nav-order-new" :class="{'theme-library-source-nav-order-select':sort===1}">
                最新
              </div>
            </div>
          </div>
        <div class="theme-library-source-list" v-if="resFileList.length!==0" v-show="!mapShow">
          <div class="theme-library-sourece-list-block pointer mb-16" style="margin-bottom: 16px;margin-right: 10px" v-for="(item,index) in resFileList" :key="index" :class="{'mr-16':index<3}" @click='detail(item)'>
            <div class="theme-library-sourece-list-image-div">
              <img class="theme-library-sourece-list-image" :src="item.cover">
              <div class="theme-library-sourece-list-source-type">
                <img :src="require('../style/img/theme-library-sourece-type-video-logo/theme-library-sourece-type-video-logo.png')">
                <div class="message">
                  {{item.resFileTypeName}}
                </div>
              </div>
            </div>
            <div class="theme-library-sourece-list-title">
              {{item.name}}
            </div>
            <div class="theme-library-sourece-list-caption">
              {{item.intro}}
            </div>
            <div class="theme-library-sourece-list-info">
              {{item.ndInfo}}
            </div>
          </div>
        </div>
          <div v-show="!mapShow" style="min-height: 500px" v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>

<!-- 分页 -->
          <!-- 分页 -->
          <div  class="block" v-show="pages.total&&!mapShow" style="text-align:center;margin-bottom: 16px">
            <el-pagination
                @current-change="handleCurrentChange"
                :page-size="12"
                layout="total, prev, pager, next"
                :total="pages.total">
            </el-pagination>
          </div>

          <div v-show="mapShow" style="width: 100%;height: 600px;margin: 20px 0" id="container" class="map" ></div>

        </div>
        <div style="display: none;">
          <zMpInfoWindow ref="infoWindow" :info="mapPannelInfo"></zMpInfoWindow>
        </div>
  </div>
</template>

<script>
import {resFileViewLog, getResFileAllList, getResFileList} from '../api/content.js'
import zSwitch from "@/components/zSwitch.vue";
import zMap from '../utils/zMap.js'
import zMpInfoWindow from './zMpInfoWindow';
export default {
    components:{zSwitch,zMpInfoWindow},
    data(){
        return {
          mapPannelInfo: {
            id: '',
            name: '',
            icon: '',
            intro: '',
          },
          resFileList:[],
          pages:{
            currentTotal:1,
            total:0,
            pageNo:1
          },
          query:{
              tagId:null,
              tagName:null
          },
          navList:[
            {name:'全部资源',index:0,value:null},
            {name:'图文',index:1,value:'tw'},
            {name:'视频',index:2,value:'sp'},
            {name:'图片',index:3,value:'tp'},
            {name:'模型',index:4,value:'mx'},
            {name:'其他',index:5,value:'link'}
          ],
          resFileType:"",
          activeNavIndex:0,
          sort:0,
          zMap:null,
          mapShow:false
        }
    },
    watch:{
      sort(){
        this.getResFile()
      }
    },
    created(){
        this.query = this.$route.query
        // this.getResData()
        this.getResFile()
    },
    mounted() {
      // this.zMap = new zMap('container')
      this.zMap = new zMap('container', this.$refs.infoWindow.$el, this.showMapInfo)

    },
    methods:{
      showMapInfo(info) {
        // 更新面板信息
        this.mapPannelInfo = info
      },
      getResFile(){
        let base_params = {resFileType:this.resFileType,tagIdList:this.query.tagId}

        getResFileList({...base_params,pageNo:this.pages.pageNo,pageSize:16,zhpx: this.sort ===0 }).then(res=>{
          this.resFileList = res.data.result.list;
          this.pages.total=res.data.result.total
          console.log(res)
        }).catch(err=>{
          console.log(err);
        })
        getResFileAllList(base_params).then(e=>{
          this.zMap.removeClusterPoints()
          this.zMap.setCluster(e.data.result)
        })
      },
      clickNavItem(item){
        this.activeNavIndex = item.index
        this.resFileType = item.value
        this.getResFile()
      },
      handleCurrentChange(page){
        this.pages.pageNo = page
        this.getResFile()
      },
      async detail(item){
        if(item.resFileType ==='link'){
          const {data:{code}} = await resFileViewLog(item.id)
          if(code===-1){
            this.$notification['error']({
              message: '该资源需要登录后访问！',
              // description: description,
              duration: 4
            })
            setTimeout(()=>{
              this.$router.replace({ path: '/user/login',query:{redirect:this.$route.fullPath}})
            },0)
            return
          } else {
            window.open(item.link,'_blank')
          }
          return
        }
        let routeData = this.$router.resolve({ path: '/graphicSource', query: { id: item.id,...this.query } })
        window.open(routeData.href, '_blank');
      },
    }
}
</script>

<style scoped>
.pointer{
    cursor: pointer;
}
.mr-16{
    margin-right: 16px;
}
.theme-library-sourece-list-block:nth-child(4n){
  margin-right: 0;
}
</style>
