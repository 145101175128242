<template>
  <div class="container">
    <el-breadcrumb class="nav-log" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="query.resRepoId" replace :to="{ path: '/themeLibrary',query:{resRepoId:query.resRepoId,resName:query.resName} }" >{{ query.resName }}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="query.tagId" replace :to="{ path: '/sourceList',query:{tagId:query.tagId,tagName:query.tagName} }" >{{ query.tagName }}</el-breadcrumb-item>
      <el-breadcrumb-item>资源预览</el-breadcrumb-item>
    </el-breadcrumb>
    <template v-if="resFile">
      <div class="source-title">
        {{ resFile.name }}
      </div>
      <div class="source-tag-list">
        <div v-for="tag in resFile.tagList" class="source-tag">
          {{ tag.name }}
        </div>
      </div>
      <div class="source-time">
        {{ resFile.createDate }}
      </div>
      <div class="source-content" style="text-align: center">
<!--         <three3d-preview v-if="fileType==='three3d'" :file-url="resFile.url" />-->

         <img v-if="fileType==='image'" :src="resFile.url" style="max-width: 1214px;">
<!--         <video-player v-if="fileType==='video'"  :file-url="resFile.url" />-->
        <zwd-preview v-if="canPreview.includes(fileType)" :file-url="resFile.url" :file-name="resFile.name" />
      </div>

      <div class="source-download">
        <div v-if="resFile.previewUrl" class="source-download-item">
          <img class="file-logo">
          <div class="source-detail-info">
            <div class="title">
              <div class="main-title">
                {{ resFile.name }}
              </div>
              <div class="version-title">
                (预览版)
              </div>
            </div>
            <div class="type">
              文件格式：{{resFile.previewExt}}
            </div>
            <div class="size">
              文件大小：{{ resFile.previewFileSizeStr }}
            </div>
          </div>
          <div class="source-download-button" style="cursor:pointer;">
            <img src="../style/img/source-download-logo/source-download-logo.png"/>
            <div class="text" @click="downF(resFile.previewUrl)">
              下载资源
            </div>
          </div>
        </div>

        <div v-else class="source-download-item">
          <img class="file-logo">
          <div class="source-detail-info">
            <div class="title">
              <div class="main-title">
                {{ resFile.name }}
              </div>
              <div class="version-title">
                (预览版)
              </div>
            </div>
            <div class="type">
              文件格式：{{resFile.ext}}
            </div>
            <div class="size">
              文件大小：{{ resFile.fileSizeStr }}
            </div>
          </div>
          <div class="source-download-button" style="cursor:pointer;">
            <img src="../style/img/source-download-logo/source-download-logo.png"/>
            <div class="text" @click="downF">
              下载资源
            </div>
          </div>
        </div>

        <div class="source-download-item source-download-item-next">
          <img class="file-logo">
          <div class="source-detail-info">
            <div class="title">
              <div class="main-title">
                {{ resFile.name }}
              </div>
              <div class="version-title">
                (储存版本）
              </div>
            </div>
            <div class="type">
              文件格式：{{resFile.ext}}
            </div>
            <div class="size">
              文件大小：{{ resFile.fileSizeStr }}
            </div>
          </div>
          <div class="source-download-button" style="cursor:pointer;">
            <img src="../style/img/source-download-logo/source-download-logo.png"/>
            <div @click="downF" class="text">
              下载资源
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else >
      <div class="empty"></div>
    </template>
    </div>
</template>

<script>
import {getResFileDetail} from '../api/content.js'
import Vue from 'vue'
import common from "@/common.js";

export default {
  data(){
      return {
        resName:"",
        resFile:null,
        query:{

        },
        canPreview:['three3d','video','pdf','md','text','office','cad']
      }
    },
    computed:{
      fileType(){
        let type = this.resFile ? common.getFileType(this.resFile.url) : null;
        if(type==='text' && this.resFile.ext==='md') type = 'md'
        return type
      }
    },
    mounted() {
      this.getDetail()
      window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
      this.query = this.$route.query
    },
    methods:{
      async getDetail(){
        // const {data:{code}} = await resFileViewLog(this.$route.query.id)
        // if(code===-1){
        //   this.$notification['error']({
        //     message: '该资源需要登录后访问！',
        //     // description: description,
        //     duration: 4
        //   })
        //   setTimeout(()=>{
        //     this.$router.replace({ path: '/user/login',query:{redirect:this.$route.fullPath}})
        //   },0)
        //   return
        // }

        const e = await getResFileDetail(this.$route.query.id)
        this.resFile = e.data.result?.resFile || null

        const previewlist = {
          "three3d":"Three3dPreview",
          "video":"VideoPlayer",
          "pdf":"PdfViewer",
          "md":"MarkdownViewer",
          "text":"TextViewer",
          "office":"OfficeViewer",
          "cad":"CadViewer",
        }
        const pathName = previewlist[this.fileType]
        Vue.component('zwd-preview',() => import(`@/components/file/preview/${pathName}`))
      },
      downF(){
        const a = document.createElement('a');
        const url = this.resFile?.url
        if(!url) return
        a.href = url +'?response-content-type=application/octet-stream';
        a.click();
      },
    }
}
</script>

<style scoped>
.empty{
  height: calc(100vh - 220px);
}
.source-content{
  /*height: 922px;*/
}
</style>
