var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav-log"},[_c('div',{staticClass:"nav-log-home"},[_vm._m(0),_c('div',{staticClass:"nav-log-home-title pointer",on:{"click":function($event){return _vm.$router.push({name:'content'})}}},[_vm._v(" 首页 ")])]),_vm._m(1),_c('div',{staticClass:"nav-log-middle"},[_vm._v(" 良渚遗址主题库 ")]),_vm._m(2),_c('div',{staticClass:"nav-log-current"},[_vm._v(" 资源预览 ")])]),_c('div',{staticClass:"source-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_vm._m(3),_c('div',{staticClass:"source-time"},[_vm._v(" 2022年10月17日 10:30 ")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-home-image-div"},[_c('img',{staticClass:"nav-log-home-image",attrs:{"src":require("../style/img/home-logo/home-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-fh"},[_c('img',{staticClass:"nav-log-arrow",attrs:{"src":require("../style/img/arrow-red/arrow-red.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-log-fh"},[_c('img',{staticClass:"nav-log-arrow",attrs:{"src":require("../style/img/arrow-gray/arrow-gray.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-tag-list"},[_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 距今5300-4000年 长江下游 ")]),_c('div',{staticClass:"source-tag"},[_vm._v(" 考古类 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-text1"},[_vm._v(" “打脸来得太快，就像龙卷风”不止适用于酷爱立人设的娱乐圈，历史界也屡试不爽。一直以来，西方学者都认为中华文明只始于距今3500年前后的殷商时期，没想到今年7月份“良渚古城遗址”就被联合国教科文组织列入了《世界遗产名录》。这意味着什么？ "),_c('br'),_c('br'),_vm._v(" 中国新石器这一被远远低估的时代正清晰地展现在世人面前，诉说着来自五千多年前的文明，它改写了中国历史，更改写了世界历史！ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-content"},[_c('img',{staticClass:"model-image",attrs:{"src":require("../style/img/tmp/tmp-model/tmp-model.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-text2"},[_vm._v(" 80年前的意外惊喜"),_c('br'),_c('br'),_vm._v(" 良渚文明的发现震惊海内外，但很少有人知道为了挖掘出这颗蒙尘的珍珠，竟走过了80个漫长岁月……"),_c('br'),_c('br'),_vm._v(" 良渚，指的是距今5300-4300年期间，在中国南方的长江下游环太湖流域曾经存在过的一个文明，它是迄今发现的中国大地上最早的国家。以良渚古城为核心的遗址就是良渚文明的中心。而关于它的发现，还要回溯到1936年。"),_c('br'),_c('br'),_vm._v(" 当时，浙江省立西湖博物馆的一位馆员施昕更，在对位于古荡的新石器时代遗址进行考古发掘时发现了一件黑陶。他觉得，类似的黑陶在自己的家乡良渚也曾见到过。于是不久后他就回到良渚，进行文物调查。"),_c('br'),_c('br'),_vm._v(" 在他的主持下，良渚陆续出土了黑陶、石器、玉器等数百件文物。这一次“小”发现，正式掀开了良渚遗址的一角。然而，随着次年抗战的爆发，良渚遗址的发掘和研究工作不得不被迫中断。"),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview"},[_c('div',{staticClass:"ViewingContent"},[_vm._v("查看剩余预览内容 "),_c('img',{attrs:{"src":require("../style/img/tmp/tmp-model/更多.png"),"alt":""}})]),_c('div',{staticClass:"putAwayContent"},[_vm._v("收起预览内容 "),_c('img',{attrs:{"src":require("../style/img/tmp/tmp-model/收起.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-download"},[_c('div',{staticClass:"source-download-item"},[_c('img',{staticClass:"model-logo"}),_c('div',{staticClass:"source-detail-info"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"main-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_c('div',{staticClass:"version-title"},[_vm._v(" (预览版) ")])]),_c('div',{staticClass:"type"},[_vm._v(" 文件格式：max ")]),_c('div',{staticClass:"size"},[_vm._v(" 文件大小：18.78MB ")])]),_c('div',{staticClass:"source-download-button"},[_c('img',{attrs:{"src":require("../style/img/source-download-logo/source-download-logo.png")}}),_c('div',{staticClass:"text"},[_vm._v(" 下载资源 ")])])]),_c('div',{staticClass:"source-download-item source-download-item-next"},[_c('img',{staticClass:"model-logo"}),_c('div',{staticClass:"source-detail-info"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"main-title"},[_vm._v(" 良渚遗址是中华五千年文明的实证？ ")]),_c('div',{staticClass:"version-title"},[_vm._v(" (储存版本） ")])]),_c('div',{staticClass:"type"},[_vm._v(" 文件格式：max ")]),_c('div',{staticClass:"size"},[_vm._v(" 文件大小：18.78MB ")])]),_c('div',{staticClass:"source-download-button"},[_c('img',{attrs:{"src":require("../style/img/source-download-logo/source-download-logo.png")}}),_c('div',{staticClass:"text"},[_vm._v(" 下载资源 ")])])])])
}]

export { render, staticRenderFns }